<template>
  <div class="header-container">
    <img
      class="header-logo"
      :src="!!organizationLogo ? organizationLogo : require('@/assets/curved-headers/logo.png')" />
    <h1 class="header-title text-uppercase">{{ title }}</h1>
    <h2 class="header-subtitle">{{ subtitle }}</h2>
  </div>
</template>

<script>
  import CurvedHeaderMixin from '@/components/general/curved-header/CurvedHeader.mixin.vue';
  export default {
    mixins: [CurvedHeaderMixin],
  };
</script>

<style lang="scss" scoped>
  .header-container {
    position: relative;
    overflow: hidden !important;
    height: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      height: 200%;
      width: 200%;
      background-image: linear-gradient(90deg, var(--v-primaryColor-base) 31.9%, var(--v-secondaryColor-base) 67.96%);
      border-radius: 50%;
    }
  }
  .header-logo {
    position: absolute;
    left: 32px;
    top: 16px;
    width: 100%;
    max-width: 143px;
    z-index: 2;
  }
  .header-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.16em;
    margin-bottom: 21px;
    z-index: 2;
    width: 70%;
  }
  .header-subtitle {
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    width: 70%;
    z-index: 2;
  }
</style>
